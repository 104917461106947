@import './base/bootstrap.scss';
@import './base/bootstrap-extended.scss';
@import './base/colors.scss';
@import './base/components.scss';

// Themes
@import './base/themes/dark-layout.scss';
@import './base/themes/bordered-layout.scss';
@import './base/themes/semi-dark-layout.scss';
// @import './base/custom-rtl.scss';

// React Specific
@import 'react/index';

.bootgrid-wrapper {
    width: 90%; // 100% menos o tamanho da ".fixed-column-right"
    overflow-x: scroll;
    overflow-y: visible;
  }
  
  .fixed-column-right {
    position: absolute;
    width: 10%;
    right: 0;
  }

  .ck-editor__editable_inline {
    min-height: 150px;
 }

 .ck.ck-editor__editable_inline {
  min-height: 150px;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  padding: 12px;
}

 .customRow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 20px;  /* Isso adiciona a folga de 20px à esquerda e à direita */
}

.campoDiarioHora {
  display: flex;
  align-items: center;
  justify-content: center;
  border-color:black;
  border-width: 3px;
  border-radius:5px;
  margin-left:13px;
  text-align: center;
  height: 45px;
  width: 40px;
  background-color: #EFF1F3;
 
  

}
.campoParado {
  display: flex;
  align-items: center;
  justify-content: center;
  border-color:black;
  border-width: 3px;
  border-radius:5px;
  margin-left:13px;
  text-align: center;
  height: 45px;
  width: 40px;
  background-color: #FCEAEA;
  color:red
 }
 .campoVencido {
  display: flex;
  align-items: center;
  justify-content: center;
  border-color:black;
  border-width: 3px;
  border-radius:5px;
  margin-left:13px;
  text-align: center;
  height: 45px;
  width: 40px;
  background-color: red;
  color:white
 }
.campoAndamento {
  display: flex;
  align-items: center;
  justify-content: center;
  border-color:black;
  border-width: 3px;
  border-radius:5px;
  margin-left:13px;
  text-align: center;
  height: 45px;
  width: 40px;
  background-color: #4b7752;
  color:white
 
  

}

.campoFinalizado {
  display: flex;
  align-items: center;
  justify-content: center;
  border-color:black;
  border-width: 3px;
  border-radius:5px;
  margin-left:13px;
  text-align: center;
  height: 45px;
  width: 40px;
  background-color: #8072FB;
  color:white
 
  

}


.campoTextoPlano {
  align-items: center;
  justify-content: center;
  border-color:black;
  border-width: 3px;
  border-radius:5px;
  margin-left:13px;
  text-align: center;
  height: auto;
  width: 40px;
  background-color: #EFF1F3;
  margin:0;
  display: block;

  

}


.campoPlanoLinha{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-color:black;
  border-width: 3px;
  border-radius:5px;
  margin-left:13px;

  background-color: #F7F7F7;
 

  

}

.campoDiarioAtividade{
  display: flex;
  align-items: center;
  justify-content: center;
  border-color:black;
  border-width: 3px;
  border-radius:5px;
  margin-left:13px;
  min-height: 45px;
  padding:10px; 
  
  background-color: #F7F7F7;
 

  

}
.custom-list-group-item {
  position: relative;
  z-index: 1 !important;
}

.custom-dropdown {
  position: relative;
  z-index: 2 !important;
}

.custom-dropdown-menu {
  z-index: 9999 !important;
  position: absolute;
}